import { ADD_NEW_NOTIF, REMOVE_NOTIF } from "./constants";

export const AddNewNotif = (payload) => ({
    type: ADD_NEW_NOTIF,
    payload,
});

export const RemoveNotif = (payload) => ({
    type: REMOVE_NOTIF,
    payload,
});
