import React from "react";
import { UncontrolledAlert } from "reactstrap";
import { positions } from "react-alert";

class DropDownAlert {
    // ['primary', 'secondary', 'success', 'danger', 'warning', 'info'];

    static setAlert(alert) {
        if (alert !== this.alert) {
            this.alert = alert;
        }
    }

    static show(type, message, optionalOptions) {
        this.alert.show(message, {
            ...this.options(),
            type,
            ...optionalOptions,
        });
    }

    static template({ style, options, message, close }) {
        let newMessage = message.split(", id:");
        return (
            <div style={style}>
                <UncontrolledAlert color={options.type} onClick={close}>
                    <strong>{newMessage[0]}</strong>
                    {newMessage[1] && <p className="m-0">id: {newMessage[1]}</p>}
                </UncontrolledAlert>
            </div>
        );
    }

    static options() {
        return {
            timeout: 5000,
            position: positions.TOP_RIGHT,
        };
    }
}

export default DropDownAlert;
