import { REMOVE_LOAN_STATUS, SET_LOAN_STATUS } from "./constants";

const INIT_STATE = {};

const LoanStatus = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LOAN_STATUS:
            return { ...state, ...action.payload };
        case REMOVE_LOAN_STATUS:
            return { ...action.payload };
        default:
            return state;
    }
};

export default LoanStatus;
