import ApiRequest from "../../helpers/api_server";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {DropDownAlert} from '../../components';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';

function* login({payload: {history, reqBody}}) {
    try {
        const {data, info} = yield call(ApiRequest.post, '/user/main/login', reqBody);
        const {status, err_message} = info;
        if (status !== 'OK') {
            yield put(loginUserFailed(err_message));
            yield call(() => {
                DropDownAlert.show('danger', err_message);
            });
        } else {
            yield put(loginUserSuccess(data));
            yield call(() => {
                history.replace('/dashboard');
                history.go(0);
            });
        }
    } catch (error) {
        console.log('[login]: ', error);
        yield put(loginUserFailed('Mohon periksa jaringan anda!'));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function*  register({payload: {history, reqBody}}) {
    try {
        const {data, info} = yield call(ApiRequest.post, '/user/main/register/borrower', reqBody);
        const {status, err_message} = info;
        if (status !== 'OK') {
            yield put(loginUserFailed(err_message));
            yield call(() => {
                DropDownAlert.show('danger', err_message);
            });
        } else {
            yield put(registerUserSuccess('Registrasi berhasil, Silahkan masuk untuk mulai pengajuan'));
            yield call(() => {
                history.replace('/account/login');
                DropDownAlert.show('success', 'Registrasi berhasil, Silahkan masuk untuk mulai pengajuan');
            });
        }
    } catch (error) {
        console.log('[register]: ', error.response);
        yield put(registerUserFailed('Mohon periksa jaringan anda!'));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
