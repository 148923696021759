import {createSelector} from 'reselect';

const selectAuth = state => state.Auth;

export const selectError = createSelector(
    [selectAuth],
    auth => auth.error,
)
export const selectLoading = createSelector(
    [selectAuth],
    auth => auth.loading,
);

export const selectUser = createSelector(
    [selectAuth],
    auth => auth.user,
);

export const selectMessage = createSelector(
    [selectAuth],
    auth => auth.message,
);
