import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Layout from "./layout/reducers";
import Auth from "./auth/reducers";
import User from "./user/reducers";
import AppMenu from "./appMenu/reducers";
import Notification from "./notification/reducers";
import LoanStatus from "./loanStatus/reducers";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["Auth", "Notification", "LoanStatus"],
};

const rootReducer = combineReducers({
    Auth,
    User,
    AppMenu,
    Layout,
    Notification,
    LoanStatus,
});

export default persistReducer(persistConfig, rootReducer);
