import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import {createStructuredSelector} from 'reselect';
import {selectUser} from '../redux/auth/selectors';
import {selectLayoutType} from '../redux/layout/selectors';
import { connect } from 'react-redux';

import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes } from './index';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerticalLayout = Loadable({
    loader: () => import('../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

function Routes({user, layoutType, props}){

    // returns the layout
    const getLayout = () => {
        if (user === null) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }
    const Layout = getLayout();
    return (
        <BrowserRouter>
            <Layout {...props}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            !route.children ?
                                <route.route
                                    key={index}
                                    path={route.path}
                                    roles={route.roles}
                                    exact={route.exact}
                                    component={route.component}></route.route>
                                : null
                        );
                    })}
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

const mapStateToProps = createStructuredSelector({
    user: selectUser,
    layoutType: selectLayoutType
});

export default connect(
    mapStateToProps
)(Routes);
