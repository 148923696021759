import React, { useEffect } from 'react';
import Routes from './routes/Routes';
import { useAlert } from "react-alert";
import DropDownAlert from "./components/DropDownAlert";
import './assets/scss/theme.scss';


function App() {
  const alert = useAlert();
  useEffect(() => {
    DropDownAlert.setAlert(alert);
  }, []);

  return <Routes />;
}

export default App;
