import {
    SET_USER,
    REMOVE_USER
} from './constants';

const INIT_STATE = {
    currentUser: null
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, currentUser: action.payload };
        case REMOVE_USER:
            return { ...state, ...INIT_STATE};
        default:
            return { ...state };
    }
};

export default User;
