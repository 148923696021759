import React from "react";
import {Spinner} from "reactstrap";

const SimpleLoading = () => (
    <div className="text-center mt-3">
        <Spinner color={'#369378'} size="sm" className="mr-2"/>
        <strong>Sedang memuat data</strong>
    </div>
);

export default SimpleLoading;

