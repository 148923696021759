import Axios from "axios";
import { store } from "../redux/store";
import { notAuthorized } from "../redux/auth/actions";
import { DropDownAlert } from "../components";
import { AddNewNotif } from "../redux/notification/actions";

const ApiServer = Axios.create({
    timeout: 60000,
});

ApiServer.interceptors.request.use(
    function (config) {
        const user = store.getState().Auth.user;
        config.headers.Authorization = user ? user.token : undefined;
        config.headers["X-DAYA-EMAIL"] = user ? user.email : undefined;
        config.headers["X-DAYA-ROLE"] = user ? user.user_type.type_name : undefined;
        // console.log('[ApiServer Request]: ', config);
        return config;
    },
    function (error) {
        // console.log('[ApiServer Request Error]: ', error.response);
        return Promise.reject(error);
    }
);

ApiServer.interceptors.response.use(
    function (response) {
        // console.log('[ApiServer Response]: ', response);
        return response;
    },
    function (error) {
        // console.log('[ApiServer Response Error]: ', error.response);
        const traceId = error.response.headers["x-daya-trace-id"];

        if (error.response && error.response.status === 401) {
            store.dispatch(notAuthorized());
        }
        if (error.response && error.response.status === 400 && error.response.data) {
            const { info } = error.response.data;
            if (info) DropDownAlert.show("danger", `${info.err_message}, id: ${traceId}`);
            store.dispatch(AddNewNotif({ message: info.err_message, trace_id: traceId }));
        }
        if (error.response && error.response.status === 500) {
            const { info } = error.response.data;
            if (error.response && error.response.status === 500) {
                const { info } = error.response.data;
                if (info) {
                    DropDownAlert.show("danger", `${info.err_message}, id: ${traceId}`);
                } else {
                    DropDownAlert.show(
                        "danger",
                        `Saat ini kami sedang melalukan perbaikan, kami akan segera melayan anda!, id: ${traceId}`
                    );
                }
                store.dispatch(AddNewNotif({ message: info.err_message, trace_id: traceId }));
            }
            if (!error.response) {
                const { info } = error.response.data;
                DropDownAlert.show("danger", `Mohon periksa koneksi jaringan anda!, id: ${traceId}`);
                store.dispatch(AddNewNotif({ message: info.err_message, trace_id: traceId }));
            }
            return Promise.reject(error);
        }
    }
);

class ApiRequest {
    static post(url, reqBody) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiServer.post(`${process.env.REACT_APP_API_URL}${url}`, reqBody);
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static put(url, reqBody) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiServer.put(`${process.env.REACT_APP_API_URL}${url}`, reqBody);
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static async get(url, configs) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiServer.get(`${process.env.REACT_APP_API_URL}${url}`, configs);
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static async getDocument(params) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiServer.get(
                    `${process.env.REACT_APP_API_DOCUMENT}/api/public/v1/generate/pdf`,
                    {
                        responseType: "blob",
                        params,
                    }
                );
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static async getDocumentContract() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiServer.get(`${process.env.REACT_APP_DOC_CONTRACT}`, {
                    responseType: "blob",
                });
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default ApiRequest;
