export const thousandSeparator = (number) => {
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
};

export const formatNumberComma = (number, after = 2) => {
    let num = parseFloat(number);
    if (num === 0) return 0;

    return num.toFixed(after).replace(/\.?0+$/, "");
};

export const isImageFile = (filename) => {
    const extention = getExtentionFile(filename);
    return /(jpe?g|jpg|png|gif|bmp)$/i.test(extention);
};

export const getExtentionFile = (filename) => {
    return (filename.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
};

export const validateFile = (filetype) => {
    let valid = false;
    const splitFile = filetype.split("/");
    if (/(jpe?g|jpg|png|gif|bmp)$/i.test(splitFile[1])) {
        valid = true;
    } else if (/(pdf)$/i.test(splitFile[1])) {
        valid = true;
    }
    return valid;
};

export const allowances = [
    {
        min: 0,
        max: 1000000,
    },
    {
        min: 1000000,
        max: 2000000,
    },
    {
        min: 2000000,
        max: 3000000,
    },
    {
        min: 3000000,
        max: 4000000,
    },
    {
        min: 4000000,
        max: 5000000,
    },
];
