import { ADD_NEW_NOTIF, REMOVE_NOTIF } from "./constants";

const INIT_STATE = [];

const Notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_NEW_NOTIF:
            return [...state, { id: state.length + 1, ...action.payload, date: new Date() }];
        case REMOVE_NOTIF:
            return [...action.payload];
        default:
            return state;
    }
};

export default Notification;
